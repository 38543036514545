import React, { useState} from 'react';
import 'bulma';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardImage,
  Checkbox,
  Column,
  Columns,
  Container,
  Control,
  Content,
  Delete,
  Field,
  Help,
  Hero,
  HeroBody,
  Message,
  MessageHeader,
  MessageBody,
  Modal,
  ModalBackground,
  ModalContent,
  ModalClose,
  Notification,
  Title,
  Subtitle
} from 'bloomer';
import Layout from './layout';

// import DayPicker from 'react-day-picker';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';

import {RangeCalendar} from './range-calendar'
import { getAccessToken } from "../utils/auth"



import XLSX from 'xlsx'
import { AST_False } from 'terser';

let gIdCount = 0


const formatDate = d => {
  var formatted = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`.replace(/\n|\r/g, '');

  return formatted

}

const readFileSync = (file) =>{
  return new Promise((resolve, reject) => {

    console.log("readFileSycn:here")
    let reader = new FileReader();

    reader.onload = (e) => {
      let s = e.target.result.substr(e.target.result.indexOf(',')+1)
      resolve(s);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  })
}

const readBinFileSync = (file) =>{
  return new Promise((resolve, reject) => {

    console.log("readFileSycn:here")
    let reader = new FileReader();

    reader.onload = (e) => {
      let data = new Uint8Array(e.target.result)
      resolve(data);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })
}

export const FileSelector = (prop)=>{

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [keys, setKeys] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState(null);
  const [messageDialogProps, setMessageDialogProps] = useState({message:"", isShown:false})

  const handleOnChangeDate = (_fromDate, _toDate) => {
    console.log("__fromDate:", _fromDate);
    console.log("__toDate:", _toDate);

    setFromDate(_fromDate)
    setToDate(_toDate)
  }

  const convertXLSX = async (f) => { 
    let data = await readBinFileSync(f)
    let workbook = XLSX.read(data, {type: 'array'});

    let wopts = { bookType:'xlsx', bookSST:false, type:'base64' };

    let wbout = XLSX.write(workbook,wopts);

    return wbout
  }

  const parseLedger = (workbook) => {
    var first_sheet_name = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[first_sheet_name];
    var address_of_cell = 'A1';

    const columns = ['B','C','D','E','F','G'];
    const columnTitles = ['date', 'title', 'summary' , 'income', 'expense', 'balance'];

    let accountBook = [];
    let titles = [];
    let keywords = [];
    let accounts = {};
    let index = 6;

    let row;
    do{
      row = columns.map((column, i)=>{
        const address = column+index;
        /* Find desired cell */
        var desired_cell = worksheet[address];
        
        /* Get the value */
        var desired_value = (desired_cell ? desired_cell.v : undefined);
        if(i == 0 && desired_value){
          desired_value = new Date(desired_value)
        }
        return desired_value
      })
      console.log(first_sheet_name+'['+index+']:'+JSON.stringify(row))
      accountBook.push(row);

      const title = row[1];
      if(title){
        // 科目一覧/集計 作成
        if( -1 === titles.indexOf(title)){
          titles.push(title);
          accounts[title] = {'income':row[3]?row[3]:0, 'expense':row[4]?row[4]:0}
        }else{
          let account = accounts[title]
          account.income += row[3]?row[3]:0;
          account.expense += row[4]?row[4]:0;
        }
        // キーワード一覧作成
        const result = title.match(/([^\*]+)\*(.+)/)
        if( result){
          if(-1 == keywords.indexOf(result[2])){
            keywords.push(result[2])
          }
        }
      
      }

      index++;

    }while(!((row[0] === void 0)&&(row[1] === void 0)&&(row[2] === '合計')))

    // ソート

    let a = accountBook.slice(1,accountBook.length-1)
    a.sort((a,b)=>{return b[0].getTime()-a[0].getTime()})

    accountBook.forEach((item,i)=>console.log("book >>"+i+":"+JSON.stringify(item)))
    titles.forEach((item,i)=>console.log("title>>"+i+":"+JSON.stringify(item)+" : "+JSON.stringify(accounts[item])))

    const selectedTitles= titles.filter(item=>/\*八2019$/.test(item))
    selectedTitles.forEach((item,i)=>console.log("**title>>"+i+":"+JSON.stringify(item)+" : "+JSON.stringify(accounts[item])))

    keywords.forEach((item,i)=>console.log("**key>>"+i+":"+JSON.stringify(item)))

    console.log(JSON.stringify(accounts))


    a.forEach((item,i)=>console.log("book >>"+i+":"+JSON.stringify(item)))


    return keywords
  }
  const showMessageDialog = msg =>{
    setMessageDialogProps({message:msg, isShown:true})
  }

  const closeMessageDialog = () =>{
    setMessageDialogProps({message:'', isShown:false})
  }
  const validateParams = () => {

    if(!selectedFiles || selectedFiles.length===0){
      // エラーダイアログ表示
      console.log("エラー1")
      showMessageDialog("入力対象の出納帳が指定されていません。")
      return false
    }else if(!keys || !keys.some(item=>item.value)){
      // エラーダイアログ表示
      console.log("エラー2")
      showMessageDialog("集計対象のキーワードの指定がありません")      
      return false
    }else if(!fromDate || !toDate){
      // エラーダイアログ表示
      console.log("エラー3")
      showMessageDialog("会計期間の指定がありません")
      return false
    }
    return true
  }

  const generateFinancialReport = () => {
    try {
      console.log('generate-financial-report:activated.')

      if(false === validateParams()){
        return
      }

      console.log('generate-financial-report:getToken.')
      getAccessToken()
      .then((token)=>{
        (async ()=>{
          console.log('access-token:'+token)

          // build parameters
          // let formData = new FormData()
          // selectedFiles.forEach((f, i)=>{formData.append('file'+i, f)})

          // console.log("fromDate:"+fromDate)
          // console.log("toDate:"+toDate)
          // formData.append("fromDate", fromDate);
          // formData.append("toDate", toDate);
        
          // 非同期処理で実装する

          let param = {}

        //console.log("build param : before")

          console.log("build param : start")
          param.fromDate = formatDate(fromDate);
          param.toDate = formatDate(toDate);
          param.files = [];

          param.keywords = keys.filter(item=>item.value).map(item=>item.name)

          console.log("build param : keywords :"+JSON.stringify(keys))
          console.log("build param : keywords :"+JSON.stringify(param.keywords))



          for( let i = 0 ; i < selectedFiles.length; ++i){
            console.log("build param : files-"+i)
            let f =  selectedFiles[i]
            let obj = {}
            obj.name = f.name
            obj.content_type = f.type
            console.log("build param : file content-"+i)
//            obj.content = await readFileSync(f)
            obj.content = await convertXLSX(f)
            param.files.push(obj)
          }
          console.log('### Request param:'+JSON.stringify(param))
          fetch(process.env.URL_FR_SERVICE, {
//          fetch("http://127.0.0.1:3000/build-accounting-report", {
                method: 'POST',
                  headers: { 
                    'Content-type' : 'application/json',
                    'Authorization': 'Bearer '+ token,
                    'Accept':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  },
                  body: JSON.stringify(param)
                 })
                 .then((response)=>{
                  return( response.blob())
                 })
                 .then(blob=>{
                  // ダウンロードさせる
                  const url = (window.URL || window.webkitURL).createObjectURL(blob)
                  const a = document.createElement('a')
                  a.href = url                 // ダウンロード先URLに指定.
                  a.download = '会計報告書.xlsx'       // ダウンロードファイル名を指定.
                  document.body.appendChild(a) // aタグ要素を画面に一時的に追加する（これをしないとFirefoxで動作しない）.
                  a.click()                    // クリックすることでダウンロードを開始.
                  document.body.removeChild(a) // 不要になったら削除.
                 })
                 .catch((e)=>{
                   throw Error('error:'+e)
                 });
    
          })()
        })




 
      }catch (error) {
      // 発生したErrorをStateにセットする
      // コンポーネントのライフサイクル関数が勝手に呼ばれる
      this.setState({ error });
    }
  }

  const handleCheckboxStatusChanged = (e) => {
    console.log('handleCheckboxStatusChanged:'+e)
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let newValue = []
    console.log( 'checkbox['+name+']')
    keys.forEach(item=>{
      if(item.id === name){
        item.value = value
        console.log( 'checkbox['+name+']: state changed:'+value)
      }
      newValue.push(item)
    })
    
    setKeys( newValue);
  }

  const ListKeyItem = (prop) => {
    console.log('ListKeyItem:'+prop.item)
    return <li key={prop.item.id}><Checkbox name={prop.item.id} checked={prop.item.value} onChange={handleCheckboxStatusChanged}>{prop.item.name}</Checkbox></li>
  }
  const ListKeys = (props) =>{

    console.log('ListKeys:'+JSON.stringify(keys))
    return(
      keys?
      <Field>
        <Control>
          <ul>
          {
            keys.map((key,i)=>{
              let id = "key-"+i
              console.log("ListKey:content:"+JSON.stringify(key))
              return React.createElement(ListKeyItem, { item: key})
              
            })
          }
          </ul>
        </Control>
      </Field>
      :<Notification isColor="danger">科目のキーが見つかりません</Notification>
    );
  }
  
  const ListItem = (props) =>{

    console.log('ListItem:'+props.text)
    return(
      <Notification isColor="primary" isFullWidth>{props.text}</Notification>
    );
  }
  
  
    // handleDrop = (files, event) => {
    //   console.log(files, event);
    // }

  const readXlsx = async(f) => {

  }
  
  const isRegisteredFile = f =>{
    if( selectedFiles){
      return(
        selectedFiles.some(_f=>{
          if(f.name === _f.name && f.size === _f.size){
            // 同一ファイルは処理しない
            console.log( "すでに登録されています:"+f.name)
            return true
          }
        })
      )
    }
    return false
  }

  const handleFileSelect=(evt)=> {
    (async () =>{
      evt.stopPropagation();
      evt.preventDefault();
  
      var _files = evt.dataTransfer.files; // FileList object.
      console.log(" file selected."+_files)
  
      //this.setState( {'files':_files})
      let output = [];
      for(let i = 0; i < _files.length; ++i ){
        let f = _files[i]
        console.log(" file >>"+JSON.stringify(f))
        console.log(" file.name >>"+JSON.stringify(f.name))
        console.log(" file.type >>"+JSON.stringify(f.size))
        console.log(" file.size >>"+JSON.stringify(f.type))

        // 登録済みファイルの検証
        if(isRegisteredFile(f)===true){
          console.log("登録しません:"+f)
          continue
        }

        // excelファイルの読み込み
//        var data = new Uint8Array(e.target.result);

        let data = await readBinFileSync(f)

        let workbook = XLSX.read(data, {type: 'array'});
        let keywords = parseLedger(workbook)


        keywords.forEach((item,i)=>console.log("#### key>>"+i+":"+JSON.stringify(item)))

        
        const addKeys = keywords.filter(item=>keys?!keys.filter(i=>{i.name===item}).length == 0:true).map(item=>{return {id:'id-'+gIdCount++,name:item, value:true}})  
        if(addKeys.length > 0){
          const newKeys = (keys?keys:[]).concat(addKeys)
          setKeys(newKeys)

        }

        // let fr = new FileReaderSync()
        // fr.onload=(e)=>{
        //   // let encode = Base64a.encode(t)

        //   let s = e.target.result.substr(e.target.result.indexOf(',')+1)
        //   console.log("---content1:---"+s)
        //   console.log("---content2:---"+e.target.result)
        // }
        // fr.readAsText(f)
        // fr.readAsArrayBuffer(f)
        // fr.readAsDataURL(f)
        output.push(f)
      }
      let newValue = (selectedFiles ? selectedFiles:[]).concat(output)
      setSelectedFiles(newValue);


  
      console.log(" file set to state."+JSON.stringify(selectedFiles))
  
      // files is a FileList of File objects. List some properties.
//      var output = [];
      // for (var i = 0, f; f = files[i]; i++) {
      //   output.push('<li><strong>', escape(f.name), '</strong> (', f.type || 'n/a', ') - ',
      //               f.size, ' bytes, last modified: ',
      //               f.lastModifiedDate.toLocaleDateString(), '</li>');
      // }
      // document.getElementById('list').innerHTML = '<ul>' + output.join('') + '</ul>';

    })()
  }
  
  const handleDragOver =(evt)=> {
      console.log(" drop over.")
      evt.stopPropagation();
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    }
    const clearSelectedFiles =(evt)=> {
      console.log(" drop over.")
      evt.stopPropagation();
      evt.preventDefault();

      setSelectedFiles(null)
    }
  return(
    <Container>
      <Columns>
        <Column>
          <Notification isColor="info"  hasTextAlign="centered"><Title hasTextColor="white">八王子わんにゃんクラブ 会計報告書作成</Title></Notification>
        </Column>
      </Columns>
    <Columns>
      <Column>
        <Box onDragOver={handleDragOver} onDrop={handleFileSelect}>
          <Message >
            <MessageHeader>ここにファイルをドロップしてください</MessageHeader>
            <MessageBody>

              <br/>
              <br/>
              <br/>
              <br/>
            </MessageBody>
          </Message>
          <Notification>
          <Help>
            <ol>
              <li>ちまたの会計のHPから現金出納帳/預金出納帳をエクセルでダウンロードしてきます</li>
              <li>ファイルをここにドラッグ＆ドロップします</li>
            </ol>
          </Help>
          </Notification>

        </Box>

      </Column>
      <Column>
        <Box >
          <Message>
            <MessageHeader>処理対象の出納帳</MessageHeader>
            <MessageBody>
              {
                selectedFiles?
                  selectedFiles.map((file, i) => {
                  console.log("file.name"+file.name)
                  return React.createElement(ListItem, { key: i, text:file.name })
                  })
                  :<Notification isMarginless isPaddingless hasTextAlign="centered">ファイルが登録されていません</Notification>
              }

            </MessageBody>

          </Message>
          <Notification>
              <Help>
                <ol start="3">
                  <li>処理対象のファイルを確認します</li>
                </ol>
              </Help>
          </Notification>

          
        </Box>
      </Column>
      <Column>
        <Box>
          <Message>
            <MessageHeader>抽出科目</MessageHeader>
            <MessageBody>
              <ListKeys></ListKeys>
            </MessageBody>
          </Message>
          <Notification>
              <Help>
                <ol start="4">
                  <li>抽出対象の科目のキーを選択します。</li>
                </ol>
              </Help>
          </Notification>
        </Box>
      </Column>
      <Column>
        <Box>
          <Message>
            <MessageHeader>会計期間の設定</MessageHeader>
            <MessageBody>
              <RangeCalendar onChangeDate={handleOnChangeDate}/>
            </MessageBody>
          </Message>
          <Notification>
              <Help>
                <ol start="5">
                  <li>報告する会計期間を設定します</li>
                </ol>
              </Help>
          </Notification>
        </Box>
      </Column>
    </Columns>
    <Columns>
      <Column>
        <Box>
          <Message>
            <MessageHeader>操作</MessageHeader>
            <MessageBody>
              <Button isColor="info" isFullWidth onClick={generateFinancialReport}>会計報告書の作成</Button>
              <br/>
              <Button isColor="danger" isFullWidth onClick={clearSelectedFiles}>登録ファイルの取り消し</Button>

            </MessageBody>
          </Message>
          <Notification>
            <Help>
              <ol start="6">
              <li><strong>会計報告書の作成</strong>を選択します</li>
              <li>ダウンロード先を指定してPCに保存します</li>
              </ol>
            </Help>
          </Notification>

        </Box>
      </Column>
    </Columns>
    <Modal isActive={messageDialogProps.isShown}>
      <ModalBackground />
      <ModalContent>
        <Message  isColor='danger'>
          <MessageHeader>
              <p>エラー</p>
              <Delete onClick={closeMessageDialog}/>
          </MessageHeader>
          <MessageBody>
              {messageDialogProps.message}
          </MessageBody>
        </Message>
      </ModalContent>
      <ModalClose />
    </Modal>
  </Container>

  );    
}


export default FileSelector;