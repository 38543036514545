import React, { PureComponent } from 'react';
import { getAccessToken } from "../utils/auth"



export class GenerateFinancialReportButton extends PureComponent {


  constructor() {
    super();
    this.state = { error: null };
  }
  handleOnClick = () => {
    try {
      console.log('generate-financial-report:activated.')

      getAccessToken()
      .then((token)=>{
        console.log('access-token:'+token)

        fetch(process.env.URL_FR_SERVICE, {
              headers: { 'Authorization': 'Bearer '+ token}
             })
             .then((response)=>{
              return( response.text())
             })
             .then(text=>{
              console.log("service resp:"+text())

             })
             .catch((e)=>{
               throw Error('error:'+e)
             });

      })
    } catch (error) {
      // 発生したErrorをStateにセットする
      // コンポーネントのライフサイクル関数が勝手に呼ばれる
      this.setState({ error });
    }
  }
  render() {
    if (this.state.error) {
      // エラーが発生していたらrender関数などでthrowする。
      throw this.state.error;
    }
    return <button onClick={this.handleOnClick}>会計報告書の作成</button>;
  }
}

export default GenerateFinancialReportButton;